import { LocationMarkerIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";

import * as React from "react";

export interface INavigationListProps {
  className;
  closeDrawer?;
}

export function NavigationList(props: INavigationListProps) {
  const links = [
    { url: "/", title: "Domov" },
    { url: "/catering", title: "Catering" },
    { url: "/ponudba", title: "Ponudba" },
    { url: "/galerija", title: "Galerija" },
    { url: "/kontakt", title: "Kontakt" },
    { url: "/reference", title: "Reference" },
    { url: "/o-nas", title: "O nas" },
    // { url: "/zemljevid", title: "Zemljevid" },
  ];
  return (
    <nav className={props.className}>
      <ul className="flex flex-col gap-6 text-center lg:flex-row lg:items-center lg:justify-end md:gap-2 lg:text-sm">
        {links.map((link) => (
          <li key={link.url} className="">
            <Link
              className="p-2 italic md:not-italic font-raleway"
              onClick={props.closeDrawer}
              to={link.url}
            >
              {link.title}
            </Link>
          </li>
        ))}
        <li className="mx-auto ">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://goo.gl/maps/MAoqbGJP4J4ZSWay6"
            className="p-2"
            onClick={props.closeDrawer}
            aria-label="Google Maps Location"
          >
            <LocationMarkerIcon className="w-4 h-4 text-gray-600 " />
          </a>
        </li>
      </ul>
    </nav>
  );
}
