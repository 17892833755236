import { useCycle } from "framer-motion";
import * as React from "react";

export interface IHamburgerProps {
  isOpen: boolean;
  toggle;
  className;
}

export function Hamburger(props: IHamburgerProps) {
  //   const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <button
      className={`relative w-10 h-10 text-gray-500 bg-white  focus:outline-none ${
        props.isOpen ? "" : ""
      } ${props.className}`}
      onClick={() => props.toggle()}
    >
      <span className="sr-only">Open main menu</span>
      <div className="absolute block w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
        <span
          aria-hidden="true"
          className={`block absolute h-0.5 w-5 bg-current transform transition  duration-200 ease-in-out
                ${props.isOpen ? "-rotate-45" : "-translate-y-1.5"}`}
        ></span>
        <span
          aria-hidden="true"
          className={`block absolute  h-0.5 w-5 bg-current  transform transition duration-200 ease-in-out${
            props.isOpen ? "   opacity-0" : " text-yellow-700 opacity-100"
          }`}
        ></span>
        <span
          aria-hidden="true"
          className={`block absolute  h-0.5 w-5 bg-current transform  transition duration-200 ease-in-out ${
            props.isOpen ? "rotate-45" : "translate-y-1.5"
          }`}
        ></span>
      </div>
    </button>
  );
}
