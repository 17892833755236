import * as React from "react";

export interface ILogoSpiricProps {
  className;
}

export function LogoSpiric(props: ILogoSpiricProps) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 256 256"
      className={props.className}
    >
      <path
        id="spiric_logo"
        d="M232.2,60.9c1.1,1.3,2,2.3,3.2,3.7c-12.6,7.6-25,15.1-36.5,22.1c-7.4-7.2-14-14.5-21.6-20.6
  c-8.9-7.1-19.2-6.6-29.2-1.9c-10.3,4.8-12.7,15.9-5,24.4c2.9,3.3,6.9,6.2,10.9,7.7c14.9,5.7,30.2,10.8,45.2,16.2
  c2.6,0.9,4.1-0.1,6.1-1.6c11.1-8.5,22.5-16.7,33.8-25c0.6-0.5,1.4-1.1,2-1.1c1.4,0,2.8,0.5,4.2,0.7c-0.7,1.2-1.2,2.8-2.2,3.5
  c-8.6,5.9-17.4,11.6-25.9,17.5c-3.2,2.2-5.9,5.2-9.3,8.2c5.4,2.3,10,3.8,14.2,6.1c10.9,5.9,19.1,14.1,20.8,27.1
  c1.1,8.2-1.3,15.6-6.1,22c-13.4,18.1-32,28.8-53.1,35.6c-1.2,0.4-3.1-0.6-4.4-1.5c-7.3-5-14.6-10.1-21.8-15.3
  c-2.9-2.1-4.8-1.4-7,1.2c-12.1,14-25.7,26.4-42,35.5c-8.9,4.9-18.2,8.3-28.6,6.1c-9.7-2.1-14.7-9.6-13.2-19.3
  c1.9-12.1,9-20.3,18.4-27.6c19.6-15,41.8-17.8,65.2-14.4c6,0.9,10,0,13.2-5.4c2.9-4.8,6.7-9,10.5-13.9c-20.5-7.6-42.9-10.3-60-28.4
  c1.5,22.9-3.1,42.3-22.4,55.7c1.5-11.5,2.9-22.6,4.3-33.7c-0.5-0.2-1-0.4-1.5-0.7c-1.4,0.7-2.9,1.4-4.3,2.2
  c-15.5,9.4-30.8,10.2-45.8-1.3c-6.4-4.9-13.2-9.5-19.1-15.1c-15.1-14.3-18.6-33.2-10-52.2c1.4-3.1,2.8-6.4,5-9
  c2.1-2.4,5.1-5,8.1-5.7c9.8-2.3,18.3-6.9,26.1-13.1c1.3-1.1,3.3-2,4.9-2c17.8,0.1,35.5,1.7,52.8,6.4c1.2,0.3,2.4,0.7,3.5,1.2
  c4.2,2,4.8,3.7,2.2,7.6c-3.1,4.7-6.3,9.3-9.6,13.9c-0.6,0.9-1.7,1.5-3.3,2.2c1-7.3-1.5-12.9-8.4-13.8c-16.2-2-32.5-3.4-48.8-4.3
  c-2.8-0.2-6.9,2.3-8.5,4.8c-6.6,10.1-10.4,21.3-7.8,33.6c1.8,8.4,7.5,14.3,13.8,19.5c6.8,5.5,13.9,10.7,21.1,15.6
  c7.9,5.4,16.7,7.5,26.3,5.2c2.4-0.6,3.8-1.3,3.9-4.1c0.4-8.7,1.1-17.4,1.7-26.2c0.2-3.3-1.2-4.4-4.4-4.3c-6,0.2-12,0.1-19.3,0.1
  c1.9-1.7,2.9-3.1,4.3-3.9c8.9-5.5,18.9-5.7,28.9-6.4c3.9-0.3,7.9-0.9,11.9-1.3c0.1,0.1,0.5,0.4,0.5,0.6c-2,10.9,4.4,17.4,12.2,22.8
  c15.1,10.4,32.5,15.5,50,20.3c0.8,0.2,2.2-0.2,2.7-0.8c2.3-2.9,4.4-6,6.8-9.3c-9-2.8-17.6-5.4-26.1-8.3c-9.6-3.3-18.9-7.4-26.7-14.2
  c-13.6-11.8-12.9-32.7,2.2-42.6c17.8-11.7,36.5-10.9,55.1-1.6c5.1,2.6,9.9,5.9,14.6,9.2c2,1.4,3.3,1.6,5.4,0.3
  C221.4,67,226.6,64.1,232.2,60.9z M149.4,184.3c-17.2-6.4-33.7-8.3-50.3-1.2C88.5,187.7,79,194,73.8,204.9c-2.8,6-3.5,12.2,0.9,17.7
  c4.4,5.5,10.8,6.7,17,4.8c6.9-2.1,13.7-5.1,19.9-8.8C126.2,209.8,138.2,198.1,149.4,184.3z M163.8,172.1c11.6,7,22.9,13.8,34.3,20.5
  c1,0.6,2.8,0.7,3.9,0.2c10.9-5.1,20.7-11.7,27.2-22.1c5.9-9.4,5-18.9-2.8-26.9c-7.5-7.7-17-12-27.3-14.6c-1-0.3-2.8,0.2-3.5,1
  c-2.4,2.9-4.4,6-6.4,9c6.8,2.7,13.5,4.9,19.7,7.9c13.9,6.7,15.9,18.7,5.1,29.8c-1.4,1.4-3.7,2-5.6,2.9c-0.3-1.7,0.1-2.4,0.4-3.1
  c3.4-6.4,2.9-9.8-3.5-13c-7.5-3.7-15.4-6.6-23.3-9.4c-1.5-0.5-4.4,0.5-5.5,1.7C171.9,161.3,167.7,167.1,163.8,172.1z"
      />
    </svg>
    // <svg
    //   version="1.1"
    //   id="Layer_1"
    //   xmlns="http://www.w3.org/2000/svg"
    //   x="0px"
    //   y="0px"
    //   viewBox="0 0 267.72 213.76"
    //   className={props.className}
    // >
    //   <path
    //     id="spiric_x5F_logo"
    //     d="M246.52,21.7c1.15,1.36,2.09,2.46,3.38,3.98c-13.47,8.16-26.65,16.16-38.93,23.6
    // c-7.92-7.63-14.94-15.48-23.06-21.95c-9.51-7.58-20.53-7-31.16-2.01c-11.01,5.17-13.53,16.99-5.38,26.06
    // c3.12,3.47,7.31,6.58,11.64,8.24c15.95,6.12,32.18,11.53,48.26,17.3c2.75,0.98,4.38-0.07,6.56-1.73
    // c11.89-9.06,24.01-17.82,36.06-26.67c0.67-0.49,1.46-1.16,2.18-1.14c1.49,0.05,2.96,0.5,4.45,0.79c-0.78,1.26-1.27,2.95-2.39,3.72
    // c-9.17,6.31-18.54,12.33-27.65,18.72c-3.43,2.4-6.33,5.56-9.91,8.78c5.78,2.44,10.67,4.07,15.15,6.47
    // c11.65,6.26,20.34,15.03,22.16,28.86c1.15,8.7-1.41,16.69-6.47,23.52c-14.33,19.3-34.19,30.68-56.65,37.94
    // c-1.28,0.41-3.36-0.64-4.69-1.56c-7.82-5.35-15.56-10.81-23.26-16.33c-3.08-2.21-5.07-1.46-7.48,1.32
    // c-12.94,14.95-27.42,28.18-44.83,37.83c-9.47,5.25-19.38,8.84-30.52,6.48c-10.36-2.2-15.64-10.22-14.03-20.6
    // c2.01-12.94,9.61-21.7,19.65-29.4c20.93-16.03,44.58-18.99,69.61-15.39c6.38,0.92,10.63,0,14.11-5.76
    // c3.07-5.09,7.16-9.58,11.19-14.83c-21.83-8.16-45.78-10.96-63.97-30.28c1.59,24.45-3.26,45.12-23.9,59.47
    // c1.55-12.26,3.05-24.09,4.54-35.91c-0.55-0.24-1.1-0.48-1.64-0.72c-1.54,0.79-3.14,1.49-4.62,2.38
    // c-16.57,9.98-32.89,10.84-48.82-1.34c-6.87-5.25-14.1-10.16-20.36-16.07C9.6,80.26,5.93,60.07,15.02,39.76
    // c1.49-3.33,2.96-6.87,5.31-9.55c2.28-2.6,5.42-5.34,8.63-6.08c10.5-2.44,19.51-7.36,27.82-13.97c1.43-1.14,3.47-2.16,5.23-2.15
    // c19.03,0.09,37.87,1.84,56.32,6.8c1.28,0.34,2.58,0.71,3.76,1.28c4.48,2.15,5.09,3.96,2.4,8.09c-3.27,5.02-6.75,9.91-10.23,14.78
    // c-0.67,0.93-1.79,1.55-3.53,2.37c1.07-7.82-1.63-13.75-8.99-14.68c-17.28-2.18-34.67-3.62-52.06-4.64
    // c-2.97-0.17-7.31,2.47-9.04,5.11C33.56,37.94,29.5,49.81,32.35,63c1.94,8.96,7.99,15.3,14.76,20.8
    // c7.23,5.87,14.78,11.38,22.47,16.66c8.45,5.8,17.78,7.96,28.01,5.56c2.61-0.61,4.05-1.43,4.2-4.42c0.46-9.31,1.18-18.61,1.84-27.92
    // c0.25-3.48-1.26-4.69-4.73-4.57c-6.42,0.22-12.85,0.06-20.64,0.06c1.98-1.83,3.12-3.26,4.59-4.18c9.45-5.92,20.2-6.07,30.83-6.83
    // c4.21-0.3,8.4-0.94,12.68-1.43c0.14,0.15,0.58,0.43,0.55,0.63c-2.18,11.6,4.7,18.57,13.02,24.31c16.13,11.13,34.7,16.52,53.32,21.65
    // c0.86,0.24,2.4-0.17,2.92-0.83c2.47-3.09,4.69-6.38,7.27-9.97c-9.61-3.04-18.79-5.73-27.82-8.84c-10.25-3.53-20.12-7.88-28.46-15.12
    // c-14.55-12.64-13.77-34.91,2.36-45.5c19.01-12.49,38.99-11.59,58.81-1.7c5.48,2.73,10.57,6.29,15.58,9.85
    // c2.12,1.5,3.54,1.67,5.71,0.34C235.01,28.22,240.54,25.16,246.52,21.7z M158.19,153.41c-18.33-6.78-36-8.84-53.65-1.24
    // c-11.31,4.87-21.47,11.55-27,23.24c-3,6.35-3.71,12.99,0.98,18.86c4.68,5.87,11.53,7.15,18.13,5.09c7.34-2.29,14.59-5.49,21.19-9.44
    // C133.37,180.61,146.21,168.12,158.19,153.41z M173.53,140.34c12.42,7.44,24.46,14.71,36.6,21.82c1.07,0.63,2.99,0.72,4.11,0.19
    // c11.61-5.45,22.04-12.44,29.03-23.59c6.27-10,5.29-20.2-2.96-28.67c-8.02-8.24-18.12-12.79-29.08-15.58
    // c-1.11-0.28-3.02,0.24-3.71,1.08c-2.51,3.07-4.65,6.43-6.86,9.58c7.24,2.87,14.36,5.21,21.05,8.43c14.79,7.1,16.95,20,5.47,31.76
    // c-1.49,1.52-3.95,2.09-5.96,3.09c-0.31-1.86,0.07-2.57,0.45-3.29c3.67-6.85,3.12-10.45-3.78-13.89
    // c-7.98-3.97-16.41-7.08-24.82-10.08c-1.6-0.57-4.72,0.49-5.9,1.86C182.17,128.84,177.65,135.03,173.53,140.34z"
    //   />
    // </svg>
  );
}
