import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import * as React from "react";
import { Hamburger } from "./hamburger";
import { LogoSpiric } from "./logo_spiric";
import { NavigationList } from "./navigation-list";
import { Link } from "gatsby";

export interface INavbar3Props {}

export function Navbar3(props: INavbar3Props) {
  const anchor = "top";
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [isOpen, toggleOpen] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      toggleOpen(!isOpen);

      // setState({ ...state, [anchor]: open });
    };

  return (
    <div>
      <div className="relative z-[999] bg-white">
        <div className="container h-24 p-4 mx-auto ">
          <div className="flex flex-row items-center ">
            <span />
            <Link className="flex flex-row items-center flex-grow gap-1" to="/">
              <LogoSpiric className="w-12 h-12 pt-1" />
              <h1 className="text-2xl uppercase font-playfair">
                Gostilna Spirić
              </h1>
            </Link>

            <NavigationList
              className="hidden lg:block"
              closeDrawer={toggleDrawer(false)}
            ></NavigationList>

            <Hamburger
              isOpen={isOpen}
              toggle={toggleDrawer(!isOpen)}
              className="overflow-hidden rounded-lg lg:hidden "
            />
          </div>
        </div>
      </div>

      <React.Fragment key={anchor}>
        <SwipeableDrawer
          anchor={anchor}
          open={isOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          className="lg:hidden "
          style={{ zIndex: 998 }}
        >
          {
            <NavigationList
              className="mt-28"
              closeDrawer={toggleDrawer(false)}
            ></NavigationList>
          }
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
