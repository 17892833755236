// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catering-tsx": () => import("./../../../src/pages/catering.tsx" /* webpackChunkName: "component---src-pages-catering-tsx" */),
  "component---src-pages-dogodki-tsx": () => import("./../../../src/pages/dogodki.tsx" /* webpackChunkName: "component---src-pages-dogodki-tsx" */),
  "component---src-pages-galerija-tsx": () => import("./../../../src/pages/galerija.tsx" /* webpackChunkName: "component---src-pages-galerija-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-piskotki-tsx": () => import("./../../../src/pages/piskotki.tsx" /* webpackChunkName: "component---src-pages-piskotki-tsx" */),
  "component---src-pages-ponudba-tsx": () => import("./../../../src/pages/ponudba.tsx" /* webpackChunkName: "component---src-pages-ponudba-tsx" */),
  "component---src-pages-reference-tsx": () => import("./../../../src/pages/reference.tsx" /* webpackChunkName: "component---src-pages-reference-tsx" */),
  "component---src-pages-splosni-pogoji-tsx": () => import("./../../../src/pages/splosni-pogoji.tsx" /* webpackChunkName: "component---src-pages-splosni-pogoji-tsx" */)
}

