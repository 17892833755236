import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "gatsby";
/* eslint-disable-next-line */
export interface FooterProps {}

export function Footer(props: FooterProps) {
  const classes = {
    footerCategory: "mb-1 text-sm font-bold leading-relaxed uppercase",
  };
  return (
    <div className="flex justify-center pt-4 mt-8 mb-2 bg-gray-50">
      <div className="container px-8">
        <div className="grid grid-cols-1 gap-5 my-6 lg:justify-items-center sm:grid-cols-2 lg:grid-cols-4 font-raleway">
          {/* justify-evenly */}
          {/* <div className="grid grid-cols-4 gap-4 my-4 justify-items-center place-items-start font-raleway"> */}
          <div className="">
            <h2 className={classes.footerCategory}>Lokacija</h2>
            <a
              href="https://goo.gl/maps/MAoqbGJP4J4ZSWay6"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* Branko Spirić s.p. <br /> */}
              Zadružna ulica 2,
              <br />
              Černelavci
              {/* <br /> */}
              <br /> 9000 Murska Sobota
            </a>
          </div>
          <div className="">
            <h2 className={classes.footerCategory}>Kontakt</h2>
            <div className="flex items-center gap-1">
              <PhoneIcon className="w-4 h-4 text-yellow-500" />
              <a href="tel:+38641343510">+386 41 343 510</a>
            </div>

            <div className="flex items-center gap-1">
              <PhoneIcon className="w-4 h-4 text-yellow-500" />
              <a href="tel:025252020">02 5252 020</a>
            </div>

            <div className="flex items-center gap-1">
              <MailIcon className="w-4 h-4 text-yellow-500" />

              <a href="mailto:gostilna.spiric@gmail.com">
                gostilna.spiric@gmail.com
              </a>
            </div>
          </div>
          <div className="">
            <h2 className={classes.footerCategory}>Odpiralni čas</h2>

            <table>
              <tbody>
                <tr>
                  <td className="pr-2">Pon:</td>
                  <td>Zaprto</td>
                </tr>
                <tr>
                  <td className="pr-2">Tor - pet:</td>
                  <td>8:00-22:00</td>
                </tr>
                <tr>
                  <td>Sobota:</td>
                  <td> 8:00-00:00</td>
                </tr>
                <tr>
                  <td>Nedelja:</td>
                  <td>8:00-20:00</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h2 className={classes.footerCategory}>Sledite nam</h2>
            <div className="flex items-center justify-start ">
              <a
                aria-label="Facebook"
                className="p-2 text-gray-500 border-2 border-gray-500 rounded-full"
                href="https://www.facebook.com/gostilna.spiric/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>

              {/* Instagram */}
              {/* <a className="text-gray-500 ">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a> */}
            </div>
          </div>
        </div>
        {/* <div className="grid justify-center grid-cols-2 gap-4 text-xs divide-x font-raleway"> */}
        {/* <div className="flex justify-center gap-4 text-xs divide-x font-raleway">
          <Link to="/splosni-pogoji" className="self-end justify-self-end">
            Splošni pogoji
          </Link>
          <Link to="/piskotki"> Piškotki</Link>
        </div> */}
        <div className="flex items-center justify-center h-16">
          <h1 className="font-raleway">©2021 Gostilna Spirić</h1>
        </div>
      </div>
    </div>
  );
}

export default Footer;
