module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gostilna Spirić","short_name":"Spirić","start_url":"/","icon":"src/images/spiric_logo_maskable.svg","icon_options":{"purpose":"any maskable"},"description":"Gostilna z dolgoletno tradicijo. Pripravljamo izvrstne jedi, ponujamo catering in ustvarjamo nepozabne dogodke.","lang":"sl","display":"standalone","background_color":"#997a33","theme_color":"#fff","localize":[{"start_url":"/de/","lang":"de","name":"Restaurant Spirić","short_name":"Restaurant Spirić","description":"Restaurant mit Tradition. Im Prekmurje, Slowenien."},{"start_url":"/en/","lang":"en","name":"Restaurant Spirić","short_name":"Restaurant Spirić","description":"Restaurant with tradition. Located in Prekmurje, Slovenia."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"92b4c0048c34a5a7fcad82d1ec08fe74"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout/layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
