import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

import "@fontsource/playfair-display/400.css";
import "@fontsource/raleway/300.css";
import "@fontsource/sacramento/400.css";
import { Navbar2 } from "./navbar2";
import { Navbar3 } from "./navbar3";
import { AnimatePresence, motion } from "framer-motion";

import { TransitionState } from "gatsby-plugin-transition-link";
import { Helmet } from "react-helmet";

/* eslint-disable-next-line */
export interface LayoutProps {}

export function Layout({ children, location }) {
  const duration = 0.5;

  // const { title, location } = this.props;

  const variants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: duration,
        delay: duration,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      transition: { duration: duration },
    },
  };

  return (
    <div className="flex flex-col h-screen overflow-auto">
      <Helmet
        htmlAttributes={{
          lang: "sl",
        }}
      />
      <Navbar3 />
      {/* <Navbar /> */}
      {/* <Navbar2 /> */}
      {/* <motion.div
        className="flex-grow bg-white "
        initial="pageInital"
        animate="pageAnimate"
        variants={{
          pageInitial: {
            opacity: 0,
          },
          pageAnimate: {
            opacity: 1,
          },
        }}
      > */}

      <AnimatePresence>
        <motion.main
          key={location.pathname}
          // key="main-div"
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
          className="flex-grow bg-white "
        >
          {children}
        </motion.main>
      </AnimatePresence>

      {/* <main clasName="flex-grow bsg-white ">
        {children}
      </main> */}

      {/* </motion.div> */}
      <Footer />
    </div>
  );
}

export default Layout;
